document.addEventListener('DOMContentLoaded', function () {

    const elementsWithBrandBarAttribute = document.querySelectorAll('[data-brand-bar]');
    if (elementsWithBrandBarAttribute.length > 0) {
        import('./modules/brand-bar.js')
            .then((brandBarModule) => {
                const brandBarElementInstance = new brandBarModule.default('[data-brand-bar]');
            })
            .catch(error => {
                console.error('Error importing BrandBar module:', error);
            });
    }

    const elementsWithHeaderAttribute = document.querySelectorAll('[data-header]');
    if (elementsWithHeaderAttribute.length > 0) {
        import('./modules/header.js')
            .then((headerModule) => {
                const headerElementInstance = new headerModule.default('[data-header]');
            })
            .catch(error => {
                console.error('Error importing Header module:', error);
            });
    }

    const elementsWithSubNavAttribute = document.querySelectorAll('[data-sub-nav]');
    if (elementsWithSubNavAttribute.length > 0) {
        import('./modules/sub-nav.js')
            .then((subNavModule) => {
                const subNavElementInstance = new subNavModule.default('[data-sub-nav]');
            })
            .catch(error => {
                console.error('Error importing sub-nav module:', error);
            });
    }

    const elementsWithFooterAttribute = document.querySelectorAll('[data-footer-nav]');
    if (elementsWithFooterAttribute.length > 0) {
        import('./modules/footer.js')
            .then((footerNavModule) => {
                const footerElementInstance = new footerNavModule.default('[data-footer-nav]');
            })
            .catch(error => {
                console.error('Error importing Footer module:', error);
            });
    }

    const elementsWithEqualHeightAttribute = document.querySelectorAll('[data-equal-height]');
    if (elementsWithEqualHeightAttribute.length > 0) {
        import('./modules/equal-height.js')
            .then((equalHeightModule) => {
                const equalHeightElementInstance = new equalHeightModule.default('[data-equal-height]');
            })
            .catch(error => {
                console.error('Error importing Equal Height module:', error);
            });
    }

    const elementsWithProductCarouselAttribute = document.querySelectorAll('[data-product-carousel]');
    if (elementsWithProductCarouselAttribute.length > 0) {
        import('./modules/product-carousel.js')
            .then((productCarouselModule) => {
                const productCarouselElementInstance = new productCarouselModule.default('[data-product-carousel]');
            })
            .catch(error => {
                console.error('Error importing Product Carousel module:', error);
            });
    }

    const elementsWithProductRibbonAttribute = document.querySelectorAll('[data-product-ribbon]');
    if (elementsWithProductRibbonAttribute.length > 0) {
        import('./modules/product-ribbon.js')
            .then((productRibbonModule) => {
                const productRibbonElementInstance = new productRibbonModule.default('[data-product-ribbon]');
            })
            .catch(error => {
                console.error('Error importing Product Ribbon module:', error);
            });
    }

    const elementsWithInteractiveCarouselAttribute = document.querySelectorAll('[data-interactive-carousel]');
    if (elementsWithInteractiveCarouselAttribute.length > 0) {
        import('./modules/interactive-carousel.js')
            .then((productRibbonModule) => {
                const interactiveCarouselElementInstance = new productRibbonModule.default('[data-interactive-carousel]');
            })
            .catch(error => {
                console.error('Error importing Interactive Carousel module:', error);
            });
    }

    const elementsWithExploreAttribute = document.querySelectorAll('[data-explore]');
    if (elementsWithExploreAttribute.length > 0) {
        import('./modules/explore.js')
            .then((exploreModule) => {
                const exploreElementInstance = new exploreModule.default('[data-explore]');
            })
            .catch(error => {
                console.error('Error importing Explore module:', error);
            });
    }

    const elementsWithParallaxImageAttribute = document.querySelectorAll('[data-parallax-image]');
    if (elementsWithParallaxImageAttribute.length > 0) {
        import('./modules/parallax-image.js')
            .then((parallaxImageModule) => {
                const parallaxImageElementInstance = new parallaxImageModule.default('[data-parallax-image]');
            })
            .catch(error => {
                console.error('Error importing Parallax Image module:', error);
            });
    }

    const elementsWithVideoRevealAttribute = document.querySelectorAll('[data-video-reveal]');
    if (elementsWithVideoRevealAttribute.length > 0) {
        import('./modules/video-reveal.js')
            .then((videoRevealModule) => {
                const videoRevealElementInstance = new videoRevealModule.default('[data-video-reveal]');
            })
            .catch(error => {
                console.error('Error importing Video Reveal module:', error);
            });
    }

    const elementsWithHeroVideoAttribute = document.querySelectorAll('[data-hero-video]');
    if (elementsWithHeroVideoAttribute.length > 0) {
        import('./modules/hero-video.js')
            .then((heroVideoModule) => {
                const heroVideoElementInstance = new heroVideoModule.default('[data-hero-video]');
            })
            .catch(error => {
                console.error('Error importing Hero Video module:', error);
            });
    }

    const elementsWithAmbientVideoAttribute = document.querySelectorAll('[data-ambient-video]');
    if (elementsWithAmbientVideoAttribute.length > 0) {
        import('./modules/ambient-video.js')
            .then((ambientVideoModule) => {
                const ambientVideoElementInstance = new ambientVideoModule.default('[data-ambient-video]');
            })
            .catch(error => {
                console.error('Error importing Ambient Video module:', error);
            });
    }

    const elementsWithFrameworkAttribute = document.querySelectorAll('[data-framework-name]');
    if (elementsWithFrameworkAttribute.length > 0) {
        import('./modules/framework.js')
            .then((frameworkModule) => {
                const heroVideoElementInstance = new frameworkModule.default('[data-framework-name]');
            })
            .catch(error => {
                console.error('Error importing framework module:', error);
            });
    }

    const elementsWithSecondaryNavAttribute = document.querySelectorAll('[data-secondary-nav]');
    if (elementsWithSecondaryNavAttribute.length > 0) {
        import('./modules/secondary-nav.js')
            .then((secondaryNavModule) => {
                const secondaryNavElementInstance = new secondaryNavModule.default('[data-secondary-nav]');
            })
            .catch(error => {
                console.error('Error importing Secondary Nav module:', error);
            });
    }

    const elementsWithVideoSliderAttribute = document.querySelectorAll('[data-video-slider]');
    if (elementsWithVideoSliderAttribute.length > 0) {
        import('./modules/video-slider.js')
            .then((videoSliderModule) => {
                const videoSliderElementInstance = new videoSliderModule.default('[data-video-slider]');
            })
            .catch(error => {
                console.error('Error importing Video Slider module:', error);
            });
    }

    const elementsWithProductSelectorCardAttribute = document.querySelectorAll('[data-product-selector-card]');
    if (elementsWithProductSelectorCardAttribute.length > 0) {
        import('./modules/product-selector-card.js')
            .then((productSelectorCardModule) => {
                const productSelectorCardElementInstance = new productSelectorCardModule.default('[data-product-selector-card]');
            })
            .catch(error => {
                console.error('Error importing Product Selector Card module:', error);
            });
    }

    const elementsWithAccordionExpandAttribute = document.querySelectorAll('[data-accordion-expand]');
    if (elementsWithAccordionExpandAttribute.length > 0) {
        import('./modules/accordion-expand.js')
            .then((accordionExpandModule) => {
                const accordionExpandElementInstance = new accordionExpandModule.default('[data-accordion-expand]');
            })
            .catch(error => {
                console.error('Error importing Accordion Expand module:', error);
            });
    }

    const elementsWithTableCarouselAttribute = document.querySelectorAll('[data-table-carousel]');
    if (elementsWithTableCarouselAttribute.length > 0) {
        import('./modules/table-carousel.js')
            .then((tableCarouselModule) => {
                const tableCarouselElementInstance = new tableCarouselModule.default('[data-table-carousel]');
            })
            .catch(error => {
                console.error('Error importing Table Carousel module:', error);
            });
    }
});